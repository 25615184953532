<template>
  <div class="home-container">
    <!-- '公司广告'区域 -->
    <div class="banner-swiper container-fluid">
      <div class="container">
        <el-row type="flex">
          <!-- 左侧描述信息 -->
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="desc-info">
              <div class="info">
                <h3>{{ $t('home.companyBannerTitle') }}</h3>
                <p v-show="langType === 'zh'">创造长期伟大的游戏<br />创造最佳的环境让团队成功</p>
              </div>
            </div>
          </el-col>
          <!-- 右侧轮播图 -->
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="block h_573">
              <el-carousel trigger="click" height="573px" arrow="never">
                <el-carousel-item v-for="(item, i) in swiperList" :key="i">
                  <img :src="item" alt="" class="swiper-img" />
                </el-carousel-item>
              </el-carousel>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- '公司简介'区域 -->
    <a id="story" class="anchor"></a>
    <div class="company-profile">
      <div class="container">
        <h2 class="h2_title">{{ $t('home.story') }}</h2>
        <el-row type="flex">
          <!-- 左侧视频区域 -->
          <el-col :xs="24" :sm="24" :md="14" :lg="12" :xl="12">
            <div class="video-box">
              <!-- <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true" :options="playerOptions"> </video-player> -->
              <img :src="gameImgList[0].gameImg" style="width:100%;height:100%;" />
              <!-- <div style="border:1px solid red;width:100%;height:100%;"></div> -->
            </div>
          </el-col>
          <!-- 右侧描述信息 -->
          <el-col :xs="24" :sm="24" :md="10" :lg="12" :xl="12">
            <div class="desc-info" v-html="$t('home.companyStoryDesc')"></div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- '产品信息'区域 -->
    <a id="game" class="anchor"></a>
    <div class="game-list">
      <div class="container">
        <h2 class="h2_title">{{ $t('home.game') }}</h2>
        <!-- 游戏横幅 -->
        <el-row>
          <el-col>
            <div class="banner-game">
              <img :src="gameBannerInfo.gameBannerImg" class="banner-game-img" />
            </div>
            <h4>
              <span>{{ gameBannerInfo.title }}</span>
              <a class="button" :href="gameBannerInfo.downloadPage" v-show="gameBannerInfo.downloadPage">详细介绍</a>
            </h4>
            <p v-html="gameBannerInfo.desc"></p>
          </el-col>
        </el-row>
        <!-- 游戏列表 -->
        <el-row type="flex" justify="space-between" class="list-box">
          <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" v-for="(item, i) in gameImgList" :key="i" class="game-list-item">
            <div class="list-item-img">
              <img :src="item.gameImg" class="img-fluid" />
              <!-- <div class="mask">
                <div class="icon" v-show="item.codeImg">
                  <img class="img-fluid" :src="item.codeImg" />
                </div>
                <div class="btn-app">
                  <a v-if="item.iosDownload" :href="item.iosDownload" target="_blank"><img :src="require('../assets/home/btn_ios.png')" class="img-fluid"/></a>
                  <a v-if="item.androidDownload" :href="item.androidDownload" target="_blank"><img :src="require('../assets/home/btn_apk.png')" class="img-fluid"/></a>
                </div>
              </div> -->
            </div>
            <h4 v-show="langType === 'zh'">
              {{ item.zhTitle }}<a class="button" :href="item.downloadPage" v-show="item.downloadPage">{{ $t('home.gameDetailPageText') }}</a>
            </h4>
            <h4 v-show="langType === 'en'">
              {{ item.enTitle }}<a class="button" :href="item.downloadPage" v-show="item.downloadPage">{{ $t('home.gameDetailPageText') }}</a>
            </h4>
            <p v-show="langType === 'zh'" v-html="item.zhDesc" />
            <p v-show="langType === 'en'" v-html="item.enDesc" />
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- '加入我们'区域 -->
    <div class="jobs container-fluid" v-show="langType === 'zh'">
      <div class="container">
        <h2 class="h2_title">{{ $t('home.jobs') }}</h2>
        <!-- 岗位列表 -->
        <el-row class="job-list" type="flex" justify="span-between">
          <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6" class="list-item">
            <a href="javascript:void(0);" @click="onLinkBtnClick('/jobs')"><img :src="require('../assets/home/job_1.png')" class="img-fluid"/></a>
            <h3>游戏UI</h3>
          </el-col>
          <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6" class="list-item">
            <a href="javascript:void(0);" @click="onLinkBtnClick('/jobs')"><img :src="require('../assets/home/job_2.png')" class="img-fluid"/></a>
            <h3>后端开发</h3>
          </el-col>
          <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6" class="list-item">
            <a href="javascript:void(0);" @click="onLinkBtnClick('/jobs')"><img :src="require('../assets/home/job_3.png')" class="img-fluid"/></a>
            <h3>产品经理</h3>
          </el-col>
          <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6" class="list-item">
            <a href="javascript:void(0);" @click="onLinkBtnClick('/jobs')"><img :src="require('../assets/home/job_4.png')" class="img-fluid"/></a>
            <h3>游戏测试</h3>
          </el-col>
        </el-row>
        <!-- 更多岗位按钮 -->
        <div class="job-more">
          <a class="button button-outline" href="javascript:void(0);" @click="onLinkBtnClick('/jobs')">更多职位</a>
        </div>
      </div>
    </div>
    <!-- '企业文化'区域 -->
    <a id="culture" class="anchor"></a>
    <div class="culture">
      <div class="container">
        <h2 class="h2_title">{{ $t('home.culture') }}</h2>
        <el-row class="culture-con">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="block h_279">
              <el-carousel trigger="click" height="279px" arrow="never">
                <el-carousel-item v-for="(item, i) in cultureImgList" :key="i">
                  <img :src="item" alt="" class="swiper-img" />
                </el-carousel-item>
              </el-carousel>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="right-info">
            <div class="desc-info">
              <p class="culture-content" v-html="$t('home.companyCultureDesc')"></p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- '联系我们'区域 -->
    <a id="contactUs" class="anchor"></a>
    <div class="contact-us">
      <div class="container">
        <h2 class="h2_title">{{ $t('home.contactUs') }}</h2>
        <el-row class="address-con">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <a :href="componyAddressLink" target="_blank">
              <img :src="companyAddressImg" alt="" class="company-address-img" />
            </a>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="right-info">
            <div class="desc-info" v-html="$t('home.companyAddressDesc')"></div>
            <img :src="companyCodeImg" class="code-img" alt="" v-show="langType === 'zh'" />
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
// 轮播图图片
// const swiperImg1 = require('../assets/home/carousel_0.png')
const swiperImg2 = require('../assets/home/carousel_1.png')
const swiperImg3 = require('../assets/home/carousel_2.png')
const swiperImg4 = require('../assets/home/carousel_3.png')
const swiperImg5 = require('../assets/home/carousel_4.png')
const swiperImg6 = require('../assets/home/carousel_5.png')
const swiperImg7 = require('../assets/home/carousel_6.png')

// 游戏横幅图片
const gameBannerImg = require('../assets/home/game_0_banner.jpg')

// 游戏图片列表
const gameImg1 = require('../assets/home/game_1.png')
const gameImg2 = require('../assets/home/game_2.png')
const gameImg3 = require('../assets/home/game_3.png')
const gameImg4 = require('../assets/home/game_4.png')
const gameImg5 = require('../assets/home/game_5.png')
const gameImg6 = require('../assets/home/game_6.png')

// '企业文化'轮播图图片
const cultureImg1 = require('../assets/home/culture_1.jpg')
// const cultureImg2 = require('../assets/home/culture_2.jpg')
const cultureImg3 = require('../assets/home/culture_3.jpg')

// '联系我们'公司地址图片
const companyAddressImg = require('../assets/home/map_cn.png')
// '联系我们'二维码图片
const companyCodeImg = require('../assets/home/contactsImg.png')

export default {
  name: 'Home',
  computed: {
    // 当前语言状态
    langType() {
      return this.$i18n.locale
    }
  },
  data() {
    return {
      // 视频组件的配置对象(因未使用视频,暂时弃用)
      // playerOptions: {
      //   playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
      //   autoplay: false, // 如果为true,浏览器准备好时开始回放。
      //   muted: false, // 默认情况下将会消除任何音频。
      //   loop: false, // 是否视频一结束就重新开始。
      //   preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
      //   language: 'zh-CN',
      //   aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
      //   fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
      //   sources: [
      //     {
      //       type: 'video/mp4' // 类型
      //       // src: require('../assets/vnbank20210325.mp4') // url地址
      //     }
      //   ],
      //   poster: '', // 封面地址
      //   notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
      //   controlBar: {
      //     timeDivider: true, // 当前时间和持续时间的分隔符
      //     durationDisplay: true, // 显示持续时间
      //     remainingTimeDisplay: false, // 是否显示剩余时间功能
      //     fullscreenToggle: true // 是否显示全屏按钮
      //   }
      // },
      // 轮播图列表数据
      swiperList: [swiperImg2, swiperImg3, swiperImg4, swiperImg5, swiperImg6, swiperImg7],
      // 游戏横幅信息
      gameBannerInfo: {
        // 游戏横幅图片
        gameBannerImg,
        zhTitle: '蛋蛋乐园',
        enTitle: 'Egg Paradise',
        zhDesc: '蛋蛋乐园是款集休闲，益智，教育，社交于一体的养成游戏。在乐园中，你可以和小伙伴一起建造乐园，学习知识，互动竞赛，DIY创作。深受广大家长推荐。',
        enDesc:
          'Dandan Paradise is a nurturing game that integrates leisure, puzzle, education, and social interaction. In the park, you can build a park with your friends, learn knowledge, interactive competitions, and DIY creations. Recommended by parents.',
        // 游戏详情页面(游戏官网或游戏下载页面)
        downloadPage: ''
      },
      // 游戏图片列表数据
      gameImgList: [
        {
          // 游戏封面图片地址
          gameImg: gameImg1,
          // 二维码图片地址(已弃用)
          // codeImg: codeImg1,
          // ios版本下载地址(已弃用)
          // iosDownload: '123',
          // android版本下载地址(已弃用)
          // androidDownload: '123',
          // 游戏详情页面(游戏官网或游戏下载页面)
          downloadPage: null,
          // 游戏标题
          zhTitle: '天空之城-魔豆奇缘',
          enTitle: 'Castle in the Sky-Magic Beans',
          // 游戏描述
          zhDesc: '魔豆起源是一款Q版横板卷轴游戏，精美的原画场景，巧妙的关卡设计，激动人心的探险剧情，这一切都在梦幻而唯美的天空之城。',
          enDesc:
            'Magic Bean Origin is a Q version of horizontal scroll game, exquisite original scenes, clever level design, exciting adventure plot, all of which are in a dreamy and beautiful sky city.'
        },
        {
          gameImg: gameImg2,
          // 游戏详情页面(游戏官网或游戏下载页面)
          downloadPage: null,
          zhTitle: '机甲动物城',
          enTitle: 'Mecha Zoological City',
          // 游戏描述
          zhDesc: '机甲动物城是一款搞怪风格的IO类坦克对战游戏。你可以选择Q萌可爱的动物驾驶员，搭配以不同战略性能的坦克，在动物城邦中疯狂开战，抢夺地盘，谁能是城中的佼佼者，我们拭目以待。',
          enDesc:
            'Mecha Animal City is a funny style IO tank battle game. You can choose Q cute and cute animal drivers, paired with tanks with different strategic performances, go crazy in the animal city state, grab the territory, who can be the best in the city, we will wait and see.'
        },
        {
          gameImg: gameImg3,
          downloadPage: null,
          zhTitle: '动物童话镇',
          enTitle: 'Animal Fairy Town',
          // 游戏描述
          zhDesc: '可爱的动物角色搭配小朋友最爱的童话剧情。阿拉丁和白雪联手是否能打败心狠手辣的红太狼呢？',
          enDesc: 'Cute animal characters match the children’s favorite fairy tale plot. Can Aladdin and Shirayuki work together to defeat the cruel Red Wolf?'
        },
        {
          gameImg: gameImg4,
          downloadPage: null,
          zhTitle: '欢乐海滩',
          enTitle: 'Joy beach',
          // 游戏描述
          zhDesc: '这是一款十分惬意的沙滩题材游戏。由沙滩排球，沙地摩托，冲浪艇，沙滩日光浴等小游戏组成。',
          enDesc: 'This is a very pleasant beach theme game. It consists of beach volleyball, sand motorcycles, surfing boats, beach sunbathing and other small games.'
        },
        {
          gameImg: gameImg5,
          downloadPage: null,
          zhTitle: '迷拟星球',
          enTitle: 'Psychedelic planet',
          // 游戏描述
          zhDesc: '在这里，你将扮演的是一个星球的管理者。大到星球上自然环境的调节，小到城市中的基本规划，均由你来完成。',
          enDesc: 'Here, you will be the manager of a planet. It is up to you to complete the adjustment of the natural environment on the planet to the basic planning of the city.'
        },
        {
          gameImg: gameImg6,
          downloadPage: null,
          zhTitle: '城市探险队',
          enTitle: 'Urban expedition',
          // 游戏描述
          zhDesc: '这是一款充满治愈气息的跑酷游戏，你需要扮演城市冒险队中的一员，在车水马龙的大街小巷中寻找需要帮助的对象。可以扶老奶奶过马路，拯救被霸凌的中学生，救助流浪的小猫小狗，画风Q萌治愈。',
          enDesc:
            'This is a parkour game full of healing breath. You need to play as a member of an urban adventure team and look for someone in need in the busy streets and lanes. You can help your grandmother to cross the road, rescue the bullied middle school students, rescue the stray cats and puppies, the style of art is cute and healed.'
        }
      ],
      // '企业文化'轮播图列表数据
      cultureImgList: [cultureImg1, cultureImg3],
      // '联系我们'公司地址图片
      companyAddressImg,
      // '联系我们'二维码图片
      companyCodeImg,
      // 公司地址地图链接
      componyAddressLink: 'https://ditu.amap.com/search?query=现代光谷世贸中心H栋503&city=420100&geoobj=114.416406%7C30.469148%7C114.42733%7C30.474163&zoom=17.5'
    }
  },
  methods: {
    // 处理函数,当导航按项被点击时触发
    onLinkBtnClick(path) {
      this.$router.push(path)
    }
  }
}
</script>

<style lang="scss" scoped>
// 主题颜色
// $themeColor: #2ca1e2;
// $themeColor: #2c9ff9;
// $themeColor: #0dcaf0;
@import '../styles/common.scss';

.home-container {
  // '公司广告'区域
  .banner-swiper {
    background-image: linear-gradient(90deg, #16a085, #f4d03f);
    background-image: linear-gradient(90deg, #2980b9, #6dd5fa, #ffffff);
    color: #fff;

    // 左侧描述信息
    .desc-info {
      display: flex;
      align-items: center;
      height: 100%;
      .info {
        display: flex;
        flex-direction: column;
        h3 {
          font-weight: bold;
          font-size: 3rem;
        }
        p {
          font-size: 1rem;
        }
      }
    }
  }
  // '公司简介'区域
  .company-profile {
    margin-top: 2rem;
    .container {
      margin-top: 0;
    }
    // 视频
    .video-box {
      width: 100%;
      height: 100%;
    }
    // 描述
    .desc-info {
      padding: 0 0 0 15px;
    }
  }
  // '产品信息'区域
  .game-list {
    margin-top: 2rem;
    .banner-game {
      width: 100%;
      // 游戏横幅
      .banner-game-img {
        width: 100%;
      }
    }
    h4 {
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;
      a.button {
        text-decoration: none;
        color: #ffffff;
        display: inline-block;
        font-weight: 400;
        text-align: center;
        vertical-align: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        // background-color: #7e4fe9;
        // border: 1px solid #7e4fe9;
        background-color: $themeColor;
        border: 1px solid $themeColor;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: 0.25rem;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        &:hover {
          color: #dddddd;
          box-shadow: 3px 3px 10px rgba(44, 161, 226, 0.6);
        }
      }
    }
    p {
      font-size: 0.9rem;
      line-height: 1.6rem;
    }
    .list-box {
      margin-left: -15px;
    }
    // 游戏列表项
    .game-list-item {
      padding: 0 15px;
      .list-item-img {
        position: relative;
        .img-fluid {
          width: 100%;
          height: auto;
        }
        .mask {
          opacity: 0;
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background-color: rgba(0.5, 0.5, 0.5, 0.5);
          transition: opacity 0.3s;
          box-shadow: 4px 4px 10px #888888;
          .icon {
            display: block;
            margin: 1.5rem auto 0;
            width: 42%;
            text-align: center;
          }
        }
        // &:hover .mask {
        // opacity: 1;
        // }
        // 下载按钮
        .btn-app {
          display: flex;
          justify-content: space-around;
          a {
            margin: 1rem auto;
            padding: 0 1rem;
            .img-fluid {
              max-width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }
  // '加入我们'区域
  .jobs {
    margin-top: 2rem;
    // color: $themeColor;
    .job-list {
      margin-bottom: 2rem;
      .list-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }
    }
    .job-more {
      display: flex;
      justify-content: center;
      a.button {
        text-decoration: none;
        display: inline-block;
        font-weight: 400;
        text-align: center;
        vertical-align: middle;
        user-select: none;
        background-color: $themeColor;
        border: 1px solid $themeColor;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: 0.25rem;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      }

      a.button-outline {
        background-color: unset;
        color: $themeColor;
        border: 2px solid $themeColor;
      }

      a.button-outline:hover {
        color: #9287ac;
      }
    }
  }
  // '企业文化'区域
  .culture {
    margin-top: 2rem;
    .culture-con {
      background: #f1f6fe no-repeat center;
      // 右侧描述信息
      .right-info {
        position: relative;
        .desc-info {
          height: 14rem;
          padding-left: 1rem;
          .culture-content {
            position: absolute;
            margin: 0;
            top: 0;
            bottom: 0;
            font-size: 1.2rem;
            line-height: 1.9rem;
            height: 14rem;
            background: url('~@/assets/home/quote.png') no-repeat 1rem 1rem;
            padding: 0.6rem 0 0 15%;
          }
        }
      }
    }
  }
  // '联系我们'区域
  .contact-us {
    margin-top: 2rem;
    ::v-deep.address-con {
      background: #f1f6fe no-repeat center;
      display: flex;
      flex-wrap: wrap;
      // 左侧地址图片
      .company-address-img {
        width: 100%;
      }
      // 右侧描述信息
      .right-info {
        position: relative;
        background: url('../assets/home/mail.png') no-repeat top right;
        text-align: center;
        .desc-info {
          margin: 1rem;
          text-align: left;
          line-height: 1.8rem;
          .fw_bold {
            cursor: pointer;
            font-weight: bold;
            color: #212529;
          }
        }
        .code-img {
          width: 30%;
          position: absolute;
          bottom: 1rem;
          right: 1rem;
        }
        @media screen and (max-width: 800px) {
          .code-img {
            width: 40%;
            position: static;
            margin-bottom: 1rem;
          }
        }
      }
    }
  }

  // 轮播图
  .block {
    &.h_279 {
      height: 279px;
    }
    &.h_573 {
      height: 573px;
    }
    // 轮播图图片
    .swiper-img {
      width: 100%;
      height: 100%;
    }
    // 轮播图指示点位置
    ::v-deep.el-carousel__indicators {
      bottom: 20px;
    }
    // 轮播图指示点样式
    ::v-deep.el-carousel__button {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: rgba($color: #000000, $alpha: 0.8);
    }
    // 轮播图指示点激活项样式
    ::v-deep.el-carousel__indicator.is-active button {
      background-color: #006cff;
    }
  }
}
</style>
