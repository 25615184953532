export default {
  home: {
    // lang: '中文简体',
    lang: '语言',
    story: '公司简介',
    game: '产品信息',
    culture: '企业文化',
    contactUs: '联系我们',
    help: '监督中心',
    jobs: '岗位招聘',
    companyAddress: '公司地址',
    companyBannerTitle: '用游戏改变生活',
    companyStoryDesc: `
  <p class="indent">
    佰悦互娱科技有限公司(简称“佰悦互娱”），成立于 2021 年，是一家专 注于国际化手机休闲娱乐游戏的移动互联网企业，完善成熟且人性化的管理体系 和薪酬福利制度。
  </p>
  <p class="indent">
    我们的核心团队成员均毕业于 985、211 知名高校，且有来自于国内一、二线互联网公司的从业经验，在游戏领域深耕超过 5 年以上，拥有极其丰富的产品设计、研发及运营经验，是一支充满活力和激情并极具凝聚力的团队，作为行业 标杆领跑者的佰悦互娱，将会立足手机游戏，建立更丰富的产品线，继续以锐意进取、不断创新的产品精神，为玩家推出更多优秀的作品，公司也将秉承“小而 精”的创业模式，持续吸纳业界优质人才。
  </p>
  <p class="indent">
    未来，佰悦互娱将会继续专注于休闲类游戏的研发，加大市场渗透力度、进 一步拓展全球业务、聚焦用户体验、持以专注的精神和独特的创意设计能力，通过自主研发和多平台精细化运营，打造更多深受玩家喜爱的手游。
  </p>`,
    companyCultureDesc: `
  使命<br />
  为了创造更好的游戏而奋斗<br />
  价值观<br />
  尊重，主动，学习，创新<br />
  对游戏热情<br />
  对工作负责<br />
  对自己无愧<br />
  让努力的人都获得回报
  `,
    companyAddressDesc: `
  <p>公司地址：武汉市东湖高新区光谷大道现代光谷世贸中心H栋503</p>
  <p>
    您可以通过我们的邮箱，和我们聊一聊您的想法<br />
    bytefun666@gmail.com
  </p>
  <p>商务联系：<span class="fw_bold">Alisa</span></p>
  `,
    gameDetailPageText: '详情介绍'
  },
  page404: {
    title: '哎呀！',
    message: '站长说你不能进入这个页面',
    tip: '请检查您输入的URL是否正确，或单击下面的按钮返回主页',
    backBtn: '返回主页'
  }
}
