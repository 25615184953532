import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入elementui组件库
import ElementUI from 'element-ui'
// 引入elementui组件库样式
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css'
// 引入element组件的语言翻译包
import i18n from './lang' // internationalization
// 引入bootstrap样式
import 'bootstrap/dist/css/bootstrap.css'
// 引入global样式
import '@/styles/index.scss'
import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'

// 全局注册组件
Vue.use(ElementUI, { i18n: (key, value) => i18n.t(key, value) })
Vue.use(VideoPlayer)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
