import Cookies from 'js-cookie'
import i18n from '@/lang'
// 本地存储cookies中语言状态的key
const LANG_TYPE_KEY = 'home-lang-type'
/**
 * 获取当前存储中的语言状态的方法
 */
export function getLangType(messages) {
  // 获取本地存储cookies中的语言状态
  const langType = Cookies.get('home-lang-type')
  // 如果本地存储cookies中存有语言状态,则返回这个语言状态
  if (langType) return langType
  // 如果本地存储cookies中不存有语言状态,则获取当前浏览器的语言状态
  const browserLang = (navigator.language || navigator.browserLanguage).toLowerCase()
  // 拿到语言库中所有的语言类型集合
  const langList = Object.keys(messages)
  // 遍历所有的语言类型,找到与当前浏览器语言相配的语言的索引
  const index = langList.findIndex((lang) => browserLang.includes(lang))
  // 如果有想匹配的语言,则返回此语言的表示,否则返回默认语言的标识
  return index > -1 ? langList[index] : 'zh'
}
/**
 * 设置当前存储中的语言状态的方法
 */
export function setLangType(langType) {
  // 更新当前语言的激活状态
  i18n.locale = langType
  // 设置本地存储cookies中的语言状态
  Cookies.set(LANG_TYPE_KEY, langType)
}
