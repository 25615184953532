import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/index.vue'
import Home from '@/views/Home.vue'
import i18n from '@/lang'

Vue.use(VueRouter)
// 路由表
const routes = [
  {
    path: '/index.html',
    redirect: '/'
  },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '',
        component: Home,
        meta: { title: '首页' }
      }
    ]
  },
  {
    path: '/jobs',
    component: Layout,
    children: [
      {
        path: '',
        component: () => import('@/views/JobRecruitment'),
        meta: { title: '岗位招聘' }
      }
    ]
  },
  {
    path: '/help',
    component: Layout,
    children: [
      {
        path: '',
        component: () => import('@/views/SupervisionCenter'),
        meta: { title: '监督中心' }
      }
    ]
  },
  {
    path: '/doc',
    redirect: '/doc/doc1',
    component: Layout,
    children: [
      {
        path: 'doc1',
        component: () => import('@/views/HelpDoc1'),
        meta: { title: '免责声明' }
      },
      {
        path: 'doc2',
        component: () => import('@/views/HelpDoc2'),
        meta: { title: '违规记录处罚规则与实施细则' }
      },
      {
        path: 'doc3',
        component: () => import('@/views/HelpDoc3'),
        meta: { title: '知识产权投诉指引' }
      }
    ]
  },
  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true
  },
  { path: '*', redirect: '/404', hidden: true }
  // 404 page must be placed at the end !!!
]
// 创建路由实例
const router = new VueRouter({
  // 路由模式
  mode: 'history',
  // 挂载的路由表
  routes
})

// 外语状态不可进入的路由名单
export const restrictedRoutes = ['/jobs', '/help', '/doc/doc1', '/doc/doc2', '/doc/doc3']

// 前置路由守卫
router.beforeEach((to, from, next) => {
  // 拿到当前语言状态
  const langType = i18n.locale
  // 如果当前语言状态不是中文,并且用户想手动跳转到受限路由
  if (langType !== 'zh' && restrictedRoutes.includes(to.path)) {
    // 强制跳转到首页
    next('/')
  } else {
    // 放行
    next()
  }
})

// 后置路由守卫
router.afterEach(() => {})

export default router
