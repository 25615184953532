export default {
  home: {
    // lang: 'English',
    lang: 'Language',
    story: 'Story',
    game: 'Game',
    culture: 'Culture',
    contactUs: 'Contact Us',
    help: 'help',
    jobs: 'join Us',
    companyAddress: 'company address',
    companyBannerTitle: 'Change your life with games',
    companyStoryDesc: `
    <p class="indent">
      Baiyue mutual Entertainment Technology Co., Ltd. (hereinafter referred to as "Baiyue mutual entertainment"), established in 2021, is a mobile Internet enterprise focusing on international mobile phone leisure and entertainment games, with a mature and humanized management system and salary and welfare system.
    </p>
    <p class="indent">
      Our core team members have graduated from 985 and 211 well-known universities, have working experience from domestic first-line and second-line Internet companies, have more than 5 years of deep cultivation in the game field, and have extremely rich experience in product design, R & D and operation. It is a dynamic, exciting and cohesive team. As a benchmark leader in the industry, Baiyue mutual entertainment, Based on mobile games, the company will establish a richer product line and continue to launch more excellent works for players with the product spirit of forge ahead and continuous innovation, The company will also adhere to the entrepreneurial model of "small and excellent" and continue to attract high-quality talents in the industry.
    </p>
    <p class="indent">
      In the future, Baiyue mutual entertainment will continue to focus on the R & D of leisure games, increase market penetration, further expand global business, focus on user experience, maintain a dedicated spirit and unique creative design ability, and create more mobile games loved by players through independent R & D and multi platform fine operation.
    </p>`,
    companyCultureDesc: `
    Mission<br />
    Strive to create better games<br />
    sense of worth<br />
    Respect, initiative, learning and innovation<br />
    Passion for games<br />
    Be responsible for work<br />
    Be worthy of yourself<br />
    Let those who work hard get rewards
    `,
    companyAddressDesc: `
    <p>503, building h, modern optical valley World Trade Center, Guanggu Avenue, Donghu high tech Zone, Wuhan</p>
    <p>
    If you want to talk about your thoughts, please contact us with the following email:<br />
    bytefun666@gmail.com
    </p>
    `,
    gameDetailPageText: 'Details description'
  },
  page404: {
    title: 'OOPS!',
    message: 'The webmaster said that you can not enter this page...',
    tip: 'Please check that the URL you entered is correct, or click the button below to return to the homepage.',
    backBtn: 'Back to home'
  }
}
