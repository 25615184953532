<template>
  <div class="base-footer-container">
    <div class="link-list">
      <a class="link-item" href="/#story">{{ $t('home.story') }}</a>
      <a class="link-item" href="/#game">{{ $t('home.game') }}</a>
      <a v-show="langType === 'zh'" class="link-item" href="javascript:void(0);" @click="onLinkBtnClick('/jobs')">{{ $t('home.jobs') }}</a>
      <a class="link-item" href="/#culture">{{ $t('home.culture') }}</a>
      <a v-show="langType === 'zh'" class="link-item" href="javascript:void(0);" @click="onLinkBtnClick('/help')">{{ $t('home.help') }}</a>
      <a class="link-item" href="/#contactUs">{{ $t('home.contactUs') }}</a>
    </div>
    <hr class="line" />
    <!-- <div class="copy">©2021 SAGI GAMES 心驰神往 <a href="https://beian.miit.gov.cn/" target="_blank">鄂ICP备2020015701号-1</a></div> -->
  </div>
</template>

<script>
export default {
  name: 'BaseFooter',
  computed: {
    // 当前语言状态
    langType() {
      return this.$i18n.locale
    }
  },
  methods: {
    // 处理函数,当导航按项被点击时触发
    onLinkBtnClick(path) {
      this.$router.push(path)
    }
  }
}
</script>

<style scoped lang="scss">
.base-footer-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
  // background-color: #000000;
  background-color: rgba(44, 161, 226, 0.8);

  .link-list {
    text-align: center;
    line-height: 6rem;
    .link-item {
      margin: 0 0.3rem;
      white-space: nowrap;
      color: white;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        color: #e0e0e0;
      }
    }
  }
  .line {
    width: 100%;
    height: 1px;
    border: 0;
    background-color: #ccc;
    padding: 0;
    margin: 0;
  }
  .copy {
    text-align: center;
    margin: 0.6rem auto;
    color: #ffffff;
    a:hover {
      color: #e0e0e0;
    }
  }
}
</style>
