<template>
  <div class="base-header-container top-nav container-fluid">
    <nav class="container navbar navbar-expand-md navbar-light">
      <a href="/" class="logo"><img src="~@/assets/home/logo.png"/></a>
      <button
        class="navbar-toggler"
        :class="{ collapsed: !isShowNavItem }"
        type="button"
        data-toggle="collapse"
        data-target="#navbarTogglerDemo02"
        aria-controls="navbarTogglerDemo02"
        :aria-expanded="isShowNavItem"
        aria-label="Toggle navigation"
        @click="onNavbarTogglerClick"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="navbar-collapse collapse" :class="{ show: isShowNavItem }" id="navbarTogglerDemo02">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" href="/#story">{{ $t('home.story') }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/#game">{{ $t('home.game') }}</a>
          </li>
          <li class="nav-item" v-show="langType === 'zh'">
            <a class="nav-link" href="javascript:void(0);" @click="onLinkBtnClick('/jobs')">{{ $t('home.jobs') }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/#culture">{{ $t('home.culture') }}</a>
          </li>
          <li class="nav-item" v-show="langType === 'zh'">
            <a class="nav-link" href="javascript:void(0);" @click="onLinkBtnClick('/help')">{{ $t('home.help') }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/#contactUs">{{ $t('home.contactUs') }}</a>
          </li>
        </ul>
        <div class="dropdown language" :class="{ show: isShowSelectLang }">
          <a class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" :aria-expanded="isShowSelectLang" @click="onSelectLangClick">
            {{ $t('home.lang') }}
          </a>
          <div class="dropdown-menu" :class="{ show: isShowSelectLang }" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" href="javascript:void(0);" @click="onSelectLangItemClick('zh')">中文简体</a>
            <a class="dropdown-item" href="javascript:void(0);" @click="onSelectLangItemClick('en')">English</a>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { setLangType } from '@/utils/lang'
import { restrictedRoutes } from '@/router/index'
export default {
  name: 'BaseHeader',
  computed: {
    // 当前语言状态
    langType() {
      return this.$i18n.locale
    }
  },
  data() {
    return {
      // 控制语言选择下拉框的显示与隐藏
      isShowSelectLang: false,
      // 控制导航项列表下拉框的显示与隐藏
      isShowNavItem: false
    }
  },
  methods: {
    // 处理函数,当选择语言下拉项被点击时触发
    onSelectLangItemClick(e) {
      // 更新全局共享状态中的语言状态
      this.$store.commit('updateLangType', e)
      // 调用方法更新当前语言状态
      setLangType(e)
      // 如果最新的语言状态不是'zh',并且当前页面是受限的页面,则跳转到首页
      if (e !== 'zh' && restrictedRoutes.includes(this.$route.fullPath)) {
        // 跳转到首页
        this.$router.push('/')
      }
      // 隐藏语言选择下拉项
      this.isShowSelectLang = false
    },
    // 处理函数,当导航项被点击时触发
    onNavItemClick(path) {
      window.location.href = path
    },
    // 处理函数,当选择语言框被点击时触发
    onSelectLangClick() {
      // 更新语言选择下拉项的显示状态
      this.isShowSelectLang = !this.isShowSelectLang
    },
    // 处理函数,当导航项折叠按钮被点击时触发
    onNavbarTogglerClick() {
      // 如果是隐藏下拉框,则更新语言选择下拉项的显示状态
      if (!this.isShowNavItem) this.isShowSelectLang = false
      // 更新导航项列表下拉框的显示状态
      this.isShowNavItem = !this.isShowNavItem
    },
    // 处理函数,当导航按项被点击时触发
    onLinkBtnClick(path) {
      this.$router.push(path)
    }
  }
}
</script>

<style lang="scss" scoped>
// 主题颜色
$themeColor: #2ca1e2 !global;
.base-header-container {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  padding: 6px 0;
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
  box-shadow: 0 2px 8px #888888;
  display: flex;
  .navbar {
    margin-top: 0;
  }
  // 左侧logo
  .logo {
    width: 40%;
  }
  // 右侧导航列表
  .navbar-nav {
    margin: 0 0 0 auto !important;
    // 语言选择框
  }
  .language {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
    color: rgba(0, 0, 0, 0.5);
    .dropdown-toggle {
      color: inherit;
      text-decoration: none;
      white-space: nowrap;
    }
  }

  // 导航项激活样式
  .navbar-light .navbar-nav .nav-link:hover {
    color: $themeColor;
  }
}
</style>
