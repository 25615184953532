<template>
  <div class="layout-container">
    <base-header></base-header>
    <router-view />
    <base-footer></base-footer>
  </div>
</template>

<script>
import BaseHeader from '@/components/BaseHeader.vue'
import BaseFooter from '@/components/BaseFooter.vue'
export default {
  name: 'Layout',
  components: {
    BaseHeader,
    BaseFooter
  }
}
</script>

<style scoped lang="scss">
.layout-container {
  position: relative;
  padding: 70px 0 10rem;
}
</style>
