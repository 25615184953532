import Vue from 'vue'
// 引入i18n插件
import VueI18n from 'vue-i18n'
// 引入elementui组件库的语言库
import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui lang
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN' // element-ui lang
// 引入自定义的语言库
import enLocale from './en'
import zhLocale from './zh'
// 引入获取当前语言状态的方法
import { getLangType } from '@/utils/lang'
import Store from '@/store'

// 注册i18n插件
Vue.use(VueI18n)

// 语言库的所有语言类型
export const messages = {
  en: {
    ...enLocale,
    ...elementEnLocale
  },
  zh: {
    ...zhLocale,
    ...elementZhLocale
  }
}
// 拿到当前语言状态
const locale = getLangType(messages)
// 将当前语言状态保存到全局共享状态中
Store.commit('updateLangType', locale)

const i18n = new VueI18n({
  // set locale
  // options: en | zh
  locale,
  // locale: getLangType(),
  // set locale messages
  messages
})

export default i18n
